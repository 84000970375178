@tailwind base;
@tailwind components;
@tailwind utilities;

@-ms-viewport {
  width: device-width;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #fff;
  font-family: Manrope, 'sans-serif';
  background-color: #0a0a0a;
  background-image: url(../../public/assets/backgroundImage.svg);
  background-position: top;
  background-repeat: no-repeat;
  overflow-x: hidden !important;
}

@layer utilities {
  @layer responsive {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}

.swiper-container {
  width: 544px;
}

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 1024px) {
  .swiper-container {
    width: 1024px;
  }
}

.vgap--1x {
  height: 0.1rem;
}
.vgap--2x {
  height: 0.2rem;
}
.vgap--3x {
  height: 0.3rem;
}
.vgap--4x {
  height: 0.4rem;
}
.vgap--5x {
  height: 0.5rem;
}
.vgap-1x {
  height: 1rem;
}
.vgap-2x {
  height: 2rem;
}
.vgap-3x {
  height: 3rem;
}
.vgap-4x {
  height: 4rem;
}
.vgap-5x {
  height: 5rem;
}

.hgap--1x {
  display: inline-block;
  vertical-align: middle;
  width: 0.1rem;
}
.hgap--2x {
  display: inline-block;
  vertical-align: middle;
  width: 0.2rem;
}
.hgap--3x {
  display: inline-block;
  vertical-align: middle;
  width: 0.3rem;
}
.hgap--4x {
  display: inline-block;
  vertical-align: middle;
  width: 0.4rem;
}
.hgap--5x {
  display: inline-block;
  vertical-align: middle;
  width: 0.5rem;
}
.hgap-1x {
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
}
.hgap-2x {
  display: inline-block;
  vertical-align: middle;
  width: 2rem;
}
.hgap-3x {
  display: inline-block;
  vertical-align: middle;
  width: 2rem;
}
.hgap-4x {
  display: inline-block;
  vertical-align: middle;
  width: 4rem;
}
.hgap-5x {
  display: inline-block;
  vertical-align: middle;
  width: 5rem;
}
